var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"mx-2 mt-3 border",staticStyle:{"border-radius":"12px 12px 0px 0px"}},[_c('div',{staticClass:"row mx-0 align-items-center border-bottom px-3 py-2"},[_c('div',{staticClass:"bg-general3 rounded-circle d-middle-center cr-pointer",staticStyle:{"width":"20px","height":"20px"},on:{"click":_vm.irAtras}},[_c('i',{staticClass:"icon-left text-white f-18"})]),_c('div',{staticClass:"col-auto text-general f-18 f-600"},[_vm._v(" Historial de actualización de inventario (CEDIS: "+_vm._s(_vm.cedis.nombre)+") ")])]),_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col-12"},[_c('tabla-general',{attrs:{"mostrar-buscador":"","data":_vm.importaciones,"class-header":"f-17","columnas":_vm.dataColumns,"alto":"calc(100vh - 271px)","fila-funcion":_vm.ver}},[_c('template',{slot:"adicionales-izquierda"},[_c('el-table-column',{attrs:{"label":"Fecha de importación","class-name":"text-center text-general","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"row mx-0 justify-center cr-pointer",on:{"click":function($event){return _vm.ver(row)}}},[_c('p',{staticClass:"col-12 f-16 text-general"},[_vm._v(" "+_vm._s(_vm._f("helper-fecha")(row.updated_at,'DD MMM Y'))+" ")])])]}}])})],1),_c('template',{slot:"adicionales-derecha"},[_c('el-table-column',{attrs:{"label":"Observación","class-name":"text-center text-general","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"row mx-0 justify-center"},[_c('el-tooltip',{attrs:{"placement":"bottom","content":row.observaciones ? row.observaciones : 'Ninguna.',"effect":"light"}},[_c('i',{staticClass:"icon-card-text-outline text-general f-25"})])],1)]}}])}),_c('el-table-column',{attrs:{"label":"Estado","class-name":"text-center text-general","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"row mx-0 justify-center"},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(row.estado === 1),expression:"row.estado === 1"}],staticClass:"icon-playlist-edit text-general2 f-18"}),_c('i',{directives:[{name:"show",rawName:"v-show",value:(row.estado === 2),expression:"row.estado === 2"}],staticClass:"icon-ok-circled-outline text-success f-18"}),_c('el-tooltip',{attrs:{"placement":"bottom","effect":"light"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('b',[_vm._v("Anulada")]),_c('br'),_vm._v(_vm._s(row.anulado_comentario)+" ")]),_c('i',{directives:[{name:"show",rawName:"v-show",value:(row.estado === 3),expression:"row.estado === 3"}],staticClass:"icon-cancel-circled-outline text-general-red f-18"})])],1)]}}])}),_c('el-table-column',{attrs:{"class-name":"text-center text-general","label":"Orden de compra","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"row mx-0 justify-center"},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(row.oc_estado === 2),expression:"row.oc_estado === 2"}],staticClass:"icon-ok-circled-outline text-success f-18",attrs:{"title":"Completo"}}),_c('i',{directives:[{name:"show",rawName:"v-show",value:(row.oc_estado === 3),expression:"row.oc_estado === 3"}],staticClass:"icon-ok-circled-outline text-warning f-18",attrs:{"title":"Incompleto"}}),_c('p',{staticClass:"text-general cr-pointer ml-1",attrs:{"title":"Ver detalle"},on:{"click":function($event){return _vm.verDetalleOrdenCompra(row.id_oc_ingreso)}}},[_vm._v(_vm._s(row.id_oc_ingreso))])])]}}])}),_c('el-table-column',{attrs:{"class-name":"text-center","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"row mx-0 justify-center"},[(row.estado == 1)?_c('div',{staticClass:"bg-general2 text-white f-14 br-20 px-3 cr-pointer",staticStyle:{"width":"90px"},on:{"click":function($event){return _vm.modalCancelar(row.id)}}},[_vm._v(" Cancelar ")]):_vm._e(),(row.estado == 2)?_c('div',{staticClass:"bg-general3 text-white f-14 br-20 px-3 cr-pointer",staticStyle:{"width":"90px"},on:{"click":function($event){return _vm.modalanularProceso(row.id)}}},[_vm._v(" Anular ")]):_vm._e()])]}}])})],1)],2)],1)]),_c('modal-anular-proceso',{ref:"modalAnularProceso",on:{"update":_vm.listar}}),_c('modal-detalle-anulado',{ref:"modalDetalleAnulado"}),_c('modalSiNo',{ref:"modalCancelarSubida",attrs:{"titulo":"Cancelar Subida","mensaje":"¿Desea descartar el archivo a importar?","icon":"close"},on:{"accion":_vm.descartarImportacion}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }