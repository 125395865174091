<template>
    <section class="mx-2 mt-3 border" style="border-radius:12px 12px 0px 0px">
        <div class="row mx-0 align-items-center border-bottom px-3 py-2">
            <div class="bg-general3 rounded-circle d-middle-center cr-pointer" style="width:20px;height:20px;" @click="irAtras">
                <i class="icon-left text-white f-18" />
            </div>
            <div class="col-auto text-general f-18 f-600">
                Historial de actualización de inventario (CEDIS: {{ cedis.nombre }})
            </div>
        </div>
        <div class="row mx-0">
            <div class="col-12">
                <tabla-general mostrar-buscador :data="importaciones" class-header="f-17" :columnas="dataColumns" alto="calc(100vh - 271px)" :fila-funcion="ver">
                    <template slot="adicionales-izquierda">
                        <el-table-column
                        label="Fecha de importación"
                        class-name="text-center text-general"
                        width="180"
                        >
                            <template slot-scope="{row}">
                                <div class="row mx-0 justify-center cr-pointer" @click="ver(row)">
                                    <p class="col-12 f-16 text-general">
                                        {{ row.updated_at | helper-fecha('DD MMM Y') }}
                                    </p>
                                </div>
                            </template>
                        </el-table-column>
                    </template>
                    <template slot="adicionales-derecha">
                        <el-table-column
                        label="Observación"
                        class-name="text-center text-general"
                        width="180"
                        >
                            <template slot-scope="{row}">
                                <div class="row mx-0 justify-center">
                                    <el-tooltip placement="bottom" :content="row.observaciones ? row.observaciones : 'Ninguna.'" effect="light">
                                        <i class="icon-card-text-outline text-general f-25" />
                                    </el-tooltip>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Estado"
                        class-name="text-center text-general"
                        width="100"
                        >
                            <template slot-scope="{row}">
                                <div class="row mx-0 justify-center">
                                    <i v-show="row.estado === 1" class="icon-playlist-edit text-general2 f-18" />
                                    <i v-show="row.estado === 2" class="icon-ok-circled-outline text-success f-18" />
                                    <el-tooltip placement="bottom" effect="light">
                                        <div slot="content">
                                            <b>Anulada</b><br />{{ row.anulado_comentario }}
                                        </div>
                                        <i v-show="row.estado === 3" class="icon-cancel-circled-outline text-general-red f-18" />
                                    </el-tooltip>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        class-name="text-center text-general"
                        label="Orden de compra"
                        width="150"
                        >
                            <template slot-scope="{row}">
                                <div class="row mx-0 justify-center">
                                    <i v-show="row.oc_estado === 2" class="icon-ok-circled-outline text-success f-18" title="Completo" />
                                    <i v-show="row.oc_estado === 3" class="icon-ok-circled-outline text-warning f-18" title="Incompleto" />
                                    <p class="text-general cr-pointer ml-1" title="Ver detalle" @click="verDetalleOrdenCompra(row.id_oc_ingreso)">{{ row.id_oc_ingreso }}</p>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        class-name="text-center"
                        width="150"
                        >
                            <template slot-scope="{row}">
                                <div class="row mx-0 justify-center">
                                    <div v-if="row.estado == 1" class="bg-general2 text-white f-14 br-20 px-3 cr-pointer" style="width:90px;" @click="modalCancelar(row.id)">
                                        Cancelar
                                    </div>
                                    <div v-if="row.estado == 2" class="bg-general3 text-white f-14 br-20 px-3 cr-pointer" style="width:90px;" @click="modalanularProceso(row.id)">
                                        Anular
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                    </template>
                </tabla-general>
            </div>
        </div>
        <!-- Partials -->
        <modal-anular-proceso ref="modalAnularProceso" @update="listar" />
        <modal-detalle-anulado ref="modalDetalleAnulado" />
        <modalSiNo
        ref="modalCancelarSubida"
        titulo="Cancelar Subida"
        mensaje="¿Desea descartar el archivo a importar?"
        icon="close"
        @accion="descartarImportacion"
        />
    </section>
</template>

<script>
import Productos from "~/services/productos/productos_stocks";
export default {
    components: {
        modalAnularProceso: () => import('./partials/modalAnularRegistro'),
        modalDetalleAnulado: () => import('./partials/modalDetalleAnulado'),
    },
    data(){
        return {
            dataColumns: [
                { valor: 'productos_numero', titulo: 'Productos Importados', class: 'text-general f-16',alinear:'center' },
                { valor: 'productos_cantidad', titulo: 'Unidades', class: 'text-general f-16', ancho: 130, alinear:'center' },
                { valor: 'productos_valor', titulo: 'Valor Inventario', class: 'text-general f-16', alinear:'center', },
                { valor: 'proveedor', titulo: 'Proveedor', class: 'text-general f-16' },
                { valor: 'productos_invalidos', titulo: 'Productos Inválidos', class: 'text-general f-16', ancho: 160, alinear:'center' },
                { valor: 'responsable', titulo: 'Responsable', class: 'text-general f-16' },
            ],
            importaciones:[],
            id_importacion:null
        }
    },
    computed:{
        id_cedis(){
            return Number(this.$route.params.id_cedis)
        },
        cedis(){
            if(this.cedis_calculo.length == 0){
                return {}
            }
            return this.cedis_calculo.find(o=>o.id == this.id_cedis)
        }
    },
    mounted(){
        this.listar()
    },
    methods: {
        async listar(){
            try {
                const {data} = await Productos.getHistorialImportaciones(this.id_cedis)
                data.listado.map(el=>{
                    el.productos_valor = this.convertMoney(el.productos_valor,this.cedis.idm_moneda)
                })
                this.importaciones = data.listado

            } catch (e){
                this.error_catch(e)
            }
        },
        ver(row){
            if(row.estado === 1){
                this.$router.push({name:'admin.cedis.importar-inventario',params:{id_importacion:row.id}})
            }
            if(row.estado === 2){
                this.$router.push({name:'admin.cedis.importar-detalle',params:{id_importacion:row.id}})
            }
            if(row.estado === 3){
                this.$refs.modalDetalleAnulado.toggle(row)
            }
        },
        modalanularProceso(id){
            this.$refs.modalAnularProceso.toggle(id);
        },
        modalCancelar(id){
            this.id_importacion = id
            this.$refs.modalCancelarSubida.toggle();
        },
        async descartarImportacion(res){
            try {
                if(!res){
                    this.$refs.modalCancelarSubida.toggle()
                    return
                }
                const {data} = await Productos.descartarImportacion(this.id_importacion)
                this.notificacion('Exito','Importacion Cancelada','success')
                this.$refs.modalCancelarSubida.toggle();
                this.listar()

            } catch (e){
                this.error_catch(e)
            }
        },
        verDetalleOrdenCompra(idOrdenCompra){
            if (idOrdenCompra != '' && idOrdenCompra != null){
                this.$router.push({name:'admin.cedis.ordenes-compra.detalle', params:{ id_oc: idOrdenCompra }});
            }
        },
        irAtras(){
            this.$router.push({name:'admin.cedis.productos'})
        }
    }
}
</script>
